<template>
  <div id="change-password">
    <CardHeader ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <img class="logo" src="~@/assets/image/password.svg" alt="" />
      <div class="change-password">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            ref="resetForm"
            novalidate
            @submit.prevent="handleSubmit(onSubmit)"
            class="form f-w6"
          >
            <ValidationProvider
              name="現在のパスワード"
              rules="required|min:6|max:25"
              v-slot="{ errors }"
            >
              <AppInput
                inputType="password"
                inputPlaceholder="現在のパスワード"
                v-model="data.current_password"
              />
              <span class="error">{{ errors[0] }}</span>
              <span v-if="show" class="error">パスワードが一致しません。</span>
            </ValidationProvider>
            <ValidationProvider
              name="新しいパスワード"
              :rules="{
                required: true,
                min: 6,
                max: 25
              }"
              v-slot="{ errors }"
              vid="confirmPassword"
            >
              <AppInput
                inputType="password"
                inputPlaceholder="新しいパスワード"
                v-model="data.new_password"
                :inputShow="true"
              />
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              name="パスワード確認"
              rules="required|confirmed:confirmPassword"
              v-slot="{ errors }"
            >
              <AppInput
                inputType="password"
                inputPlaceholder="新しいパスワード（確認）"
                v-model="data.confirm_password"
                :inputShow="true"
              />
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <button class="btn-change-password f-w3" type="submit">
              パスワードを更新する
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <card-footer ref="footer" />
  </div>
</template>

<script>
import CardFooter from "@/components/CardFooter.vue";
import CardHeader from "@/components/CardHeader";
import AppInput from "@/views/Common/AppInput";

export default {
  name: "ChangePassword",
  components: {
    "card-footer": CardFooter,
    CardHeader,
    AppInput
  },
  metaInfo() {
    return {
      title: "パスワード更新",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: {
        text: "パスワード更新",
        isShowTitle: true,
        isShowLogo: false
      },
      data: {
        current_password: "",
        new_password: "",
        confirm_password: ""
      },
      error: "",
      show: false
    };
  },
  methods: {
    onSubmit() {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("changePassword/updatePassword", this.data)
        .then(() => {
          this.$toast("パスワードの更新の成功", "通知", "success");
          this.$root.$refs.loading.finish();
          this.$refs.resetForm.reset();
          this.show = false;
        })
        .catch(error => {
          this.$root.$refs.loading.finish();
          if (error) {
            this.show = true;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/men/changePassword.scss";
@import "~@/assets/scss/_fontFamily.scss";

.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding: 57px 19px 100px 19px;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   // height: calc(100vh - 152px);
  // }
}
</style>
