<template>
  <div class="app-input">
    <input
      :type="inputType"
      :value="value"
      @keyup="updateInputValue($event.target.value)"
      required
    />
    <span>{{ inputPlaceholder }}</span>

    <button
      type="button"
      class="btn btn-see"
      v-if="inputShow && value.length > 0"
      :class="inputType === 'text' ? 'hide' : ''"
      @click="showContent()"
    >
      <img src="../../assets/image/eye.svg" alt="see" />
    </button>
  </div>
</template>

<script>
export default {
  name: "AppInput",
  props: {
    inputType: {
      type: String,
      require: true
    },
    inputPlaceholder: {
      type: String,
      require: true
    },
    inputShow: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    }
  },
  methods: {
    updateInputValue(value) {
      this.$emit("inputValue", value);
      this.$emit("input", value);
    },
    showContent() {
      if (this.inputType === "password") {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.app-input {
  position: relative;
  border-bottom: 2px solid #d9d9d9;
  margin-top: 20px;
  font-family: "Hiragino Kaku Gothic Pro W3";
  input {
    background: transparent;
    width: 100%;
    padding: 10px 0;
    z-index: 1;
    position: relative;
    font-size: 14px;

    &:focus + span,
    &:valid + span {
      transform: translateY(0);
      color: #464d77;
      font-family: "Hiragino Kaku Gothic Pro W3"; // change font w3
      font-weight: bold;
    }
  }

  span {
    color: #9f9f9f;
    position: absolute;
    left: 0;
    bottom: 100%;
    display: block;
    transform: translateY(35px);
    transition: ease 0.5s;
    z-index: 0;
    font-size: 14px;
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 0;
    z-index: 3;
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
    img {
      width: 20px;
    }

    &.hide {
      &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 2px;
        background: #9c9c9c;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  input {
    font-size: 18px !important;
  }

  span {
    font-size: 18px !important;
  }
}
</style>
