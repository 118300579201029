var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"change-password"}},[_c('CardHeader',{ref:"header",attrs:{"node":_vm.headerTitle}}),_c('div',{staticClass:"content-scroll",attrs:{"id":"content-scroll"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/image/password.svg"),"alt":""}}),_c('div',{staticClass:"change-password"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"resetForm",staticClass:"form f-w6",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"現在のパスワード","rules":"required|min:6|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AppInput',{attrs:{"inputType":"password","inputPlaceholder":"現在のパスワード"},model:{value:(_vm.data.current_password),callback:function ($$v) {_vm.$set(_vm.data, "current_password", $$v)},expression:"data.current_password"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]),(_vm.show)?_c('span',{staticClass:"error"},[_vm._v("パスワードが一致しません。")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"新しいパスワード","rules":{
              required: true,
              min: 6,
              max: 25
            },"vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('AppInput',{attrs:{"inputType":"password","inputPlaceholder":"新しいパスワード","inputShow":true},model:{value:(_vm.data.new_password),callback:function ($$v) {_vm.$set(_vm.data, "new_password", $$v)},expression:"data.new_password"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"パスワード確認","rules":"required|confirmed:confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('AppInput',{attrs:{"inputType":"password","inputPlaceholder":"新しいパスワード（確認）","inputShow":true},model:{value:(_vm.data.confirm_password),callback:function ($$v) {_vm.$set(_vm.data, "confirm_password", $$v)},expression:"data.confirm_password"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('button',{staticClass:"btn-change-password f-w3",attrs:{"type":"submit"}},[_vm._v(" パスワードを更新する ")])],1)]}}])})],1)]),_c('card-footer',{ref:"footer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }